import React, { useEffect, useRef, useState } from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { AdvisorsCard, H3, H4, ListingCard, NamedLink, Page } from '../../components';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import TopbarDesktop from '../../components/Topbar/TopbarDesktop/TopbarDesktop';
import css from './LandingPage.module.css';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import cardImage from '../../assets/images/advisorimage.png';

import seeker1 from '../../assets/images/seeker1.png';
import seeker2 from '../../assets/images/seeker2.png';
import seeker3 from '../../assets/images/seeker3.png';

import advisor1 from '../../assets/images/advisor1.png';
import advisor2 from '../../assets/images/advisor2.png';
import advisor3 from '../../assets/images/advisor3.png';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../util/routes';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useIntl } from 'react-intl';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { FormattedMessage } from '../../util/reactIntl';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

const originalObjects = [
  {
    cardImage: cardImage,
    advisorName: "Tarot Advisors",
    title: "DiamondRosesTarot",
    price: "$10.00",
  },
  {
    cardImage: cardImage,
    advisorName: "Tarot Advisors2",
    title: "DiamondRosesTarot2",
    price: "$40.00",
  },
];


const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';


export const LandingPageComponent = ({ isAuthenticated, landingListingsError, landingListingsInProgress, listings = [], user, scrollingDisabled }) => {
  const [activeTab, setActiveTab] = useState(1);
  const history = useHistory();
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const searchInputRef = useRef(null);

  const providerPlanId = config.members.providerPlanId;
  const currentUserPlanId = user?.currentUser?.attributes?.profile?.publicData?.planId;
  const isProvider = currentUserPlanId === providerPlanId;
  const { eligibleForFirstReadingPromo,
    fetchFirstReadingPromoError,
    fetchFirstReadingPromoInProgress
  } = user;
  const showPromoBanner = eligibleForFirstReadingPromo && !fetchFirstReadingPromoError && !fetchFirstReadingPromoInProgress;

  useEffect(() => {
    if (typeof window != 'undefined' && Object.keys(window.MemberSpace).includes("getMemberInfo")) {
      console.log(window.MemberSpace, '&& memberspace object &&');

      console.log(window.MemberSpace && window.MemberSpace.getMemberInfo(), 'member info at landing');
    }
  }, [])

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleStartNow = () => {
    if (isAuthenticated && isProvider) {
      // Redirect to Listing creation page
      history.push(
        createResourceLocatorString(
          'NewListingPage',
          routeConfiguration,
          { slug: draftSlug, id: draftId, type: 'new', tab: 'details' },
          {}
        )
      );
    } else if (isAuthenticated && !isProvider) {
      history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration,
        )
      );
    } else if (window && typeof window != 'undefined') {
      window.location.href = process.env.REACT_APP_JOIN_LINK
    }
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const keywords = searchInputRef.current.value;
    const keywordsMaybe = keywords ? { keywords } : {}
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, { ...keywordsMaybe, sort: 'pub_isOnline' }));
  }

  const showCreateListingLink = isAuthenticated && user && !user.currentUserHasListings;

  const landingPageTabReadings = intl.formatMessage({ id: 'LandingPage.tabReadings' });
  const landingPageTabReadingsText = intl.formatMessage({ id: "LandingPageTabReadingsText" })
  const landingPageTabJoin = intl.formatMessage({ id: 'LandingPage.tabJoin' })
  const landingPageTabJoinText = intl.formatMessage({ id: 'LandingPage.tabJoinText' })
  const landingPageStartNow = intl.formatMessage({ id: "LandingPage.StartNow" })
  const landingPageFindReaders = intl.formatMessage({ id: "LandingPage.FindReaders" })
  const landingPageAdvisorsSectionTitle = intl.formatMessage({ id: 'LandingPage.AdvisorsSectionTitle' })
  const landingPageHowItWorksSectionTitle = intl.formatMessage({ id: "LandignPage.HowItWorksSectionTitle" });
  const landingPageSeeAllLink = intl.formatMessage({ id: 'LandingPageSeeAllLink' })
  const landingPageHowItWorksSectionFirstTab = intl.formatMessage({ id: 'LaningPage.HowItWorksSectionFirstTab' })
  const landingPageHowItWorksSectionSecondTab = intl.formatMessage({ id: 'LaningPage.HowItWorksSectionSecondTab' })
  const landingPageSeekerOneText = intl.formatMessage({ id: 'LandingPage.SeekerOneText' });
  const landingPageSeekerTwoText = intl.formatMessage({ id: 'LandinfPage.SeekerTwoText' });
  const landingPageSeekerThreeText = intl.formatMessage({ id: 'LandingPage.SeekerThreeText' });
  const landingPageAdvisorOneText = intl.formatMessage({ id: 'LandingPage.advisorOneText' });
  const landingPageAdvisorTwoText = intl.formatMessage({ id: "LandingPage.advisorTwoText" });
  const landingPageAdvisorThreeText = intl.formatMessage({ id: 'LandingPageAdvisorThreeText' });
  const landingPageAdvisorOneTitle = intl.formatMessage({ id: 'LandingPage.advisorOneTitle' });
  const landingPageAdvisorTwoTitle = intl.formatMessage({ id: "LandingPage.advisorTwoTitle" });
  const landingPageAdvisorThreeTitle = intl.formatMessage({ id: 'LandingPageAdvisorThreeTitle' });
  const firstReadingPromotionBannerTxt = intl.formatMessage({ id: 'firstReadingPromotionBannerText' })

  return (
    <Page
      scrollingDisabled={scrollingDisabled}>
      <div>
        <div>
          <TopbarContainer />
        </div>

        <div className={css.mainWrapper}>
          <section className={css.heroSection}>
            <div className={css.overlay} />
            {showPromoBanner ? <div className={css.promotionBanner}>
              <span className={css.promotionText}>{firstReadingPromotionBannerTxt}</span>
            </div> : null}
            <div className={css.heroBox}>
              <div className={css.heroContainer}>
                <div className={css.tabsBox}>
                  <div
                    className={`tab ${activeTab === 1 ? css.activeTab : css.inActive}`}
                    onClick={() => handleTabClick(1)}
                  >
                    {landingPageTabReadings}
                  </div>
                  <div
                    className={`tab ${activeTab === 2 ? css.activeTab : css.inActive}`}
                    onClick={() => handleTabClick(2)}
                  >
                    {landingPageTabJoin}
                  </div>
                </div>
                <div className={css.tabContent}>
                  {activeTab === 1 &&
                    <div className={css.tabContainer}>
                      <h2>{landingPageTabReadingsText}</h2>
                      <form className={css.formInput} onSubmit={handleSearchSubmit}>
                        <input
                          type='text'
                          placeholder='Search by specialty or reading type'
                          ref={searchInputRef}
                        />
                        <button type='submit'>{landingPageFindReaders}</button>
                      </form>
                    </div>
                  }
                  {activeTab === 2 &&
                    <div className={css.tabContainer}>
                      <h2>{landingPageTabJoinText}</h2>
                      <div className={css.formInput}>
                        <button onClick={handleStartNow}>{landingPageStartNow}</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </section>
          <div className={css.secondMainWrapper}>
            <section className={css.listingCard}>
              <div className={css.advisorBox}>
                <div className={css.advisorTopSection}>
                  <h4>{landingPageAdvisorsSectionTitle}</h4>
                  <button className={css.seeAllLink} type="button" onClick={handleSearchSubmit}>See all</button>
                </div>
                <div>
                  {landingListingsError ? <div className={css.fetchListingsError}><H3> <FormattedMessage id="LandingPage.fetchListingsError" /></H3></div> : null}
                  {landingListingsInProgress ? <div className={css.fetchListingsProgress}><H3> <FormattedMessage id="LandingPage.fetchListingsInProgress" /></H3></div> : null}
                  {listings.length < 1 && !landingListingsInProgress ? <div className={css.noListingFound}><H3><FormattedMessage id='LandingPage.noListingFound' /></H3></div> : null}
                  {listings.length > 0 ? (
                    <div className={css.advisorCard}>
                      {listings.map((l) => (
                        <ListingCard intl={intl} listing={l} key={l.id['uuid']} />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </section>
            <section className={css.howItWork}>
              <div className={css.workTabBody}>
                <div style={{ display: 'flex', justifyContent: 'center' }}> <H4>{landingPageHowItWorksSectionTitle}</H4></div>
                <div className={css.workTabsBox}>
                  <div
                    className={`tab ${activeTab === 1 ? css.activeTab : css.inActive}`}
                    onClick={() => handleTabClick(1)}
                  >
                    {landingPageHowItWorksSectionFirstTab}
                  </div>
                  <div
                    className={`tab ${activeTab === 2 ? css.activeTab : css.inActive}`}
                    onClick={() => handleTabClick(2)}
                  >
                    {landingPageHowItWorksSectionSecondTab}
                  </div>
                </div>
                <div className={css.tabContentWork}>
                  {activeTab === 1 &&
                    <div className={css.wordGrid}>
                      <div className={css.gridBox}>
                        <img src={seeker1} />
                        <p>{landingPageSeekerOneText}</p>
                      </div>
                      <div className={css.gridBox}>
                        <img src={seeker2} />
                        <p>{landingPageSeekerTwoText}</p>
                      </div>
                      <div className={css.gridBox}>
                        <img src={seeker3} />
                        <p>{landingPageSeekerThreeText}</p>
                      </div>
                    </div>
                  }
                  {activeTab === 2 &&
                    <div className={css.wordGrid}>
                      <div className={css.gridBox}>
                        <img src={advisor1} />
                        <h3>{landingPageAdvisorOneTitle}</h3>
                        <p>{landingPageAdvisorOneText}</p>
                      </div>
                      <div className={css.gridBox}>
                        <img src={advisor2} />
                        <h3>{landingPageAdvisorTwoTitle}</h3>
                        <p>{landingPageAdvisorTwoText} </p>
                      </div>
                      <div className={css.gridBox}>
                        <img src={advisor3} />
                        <h3>{landingPageAdvisorThreeTitle}</h3>
                        <p>{landingPageAdvisorThreeText}</p>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </section>
          </div>
        </div>
        <FooterContainer />
      </div>
    </Page>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  const {
    landingListingsInProgress,
    landingListingsError,
    landingListingsResultIds,
  } = state.LandingPage || {};
  const listings = getListingsById(state, landingListingsResultIds);
  const { auth, user } = state || {};
  const isAuthenticated = auth?.isAuthenticated && user !== null;
  return {
    landingListingsError,
    landingListingsInProgress,
    listings,
    scrollingDisabled: isScrollingDisabled(state),
    isAuthenticated,
    user
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
